import React from "react";
import Modal from 'react-modal';

import "./Modals.css";
import "./NoticeModal.css";
import closeCross from "../../assets/closeCross.png";
import codeExample from "../../assets/code_example.png";

Modal.setAppElement('#root');

export default function NoticeModal(props) {
    return (
        <Modal
            isOpen={props.noticeModalIsOpen}
            onAfterOpen={() => { }}
            onAfterClose={() => { document.querySelector('body').style.overflow = 'auto'; }}
            onRequestClose={props.closeNoticeModal}
            contentLabel="Notice Modal"
            className="notice-modal"
            overlayClassName="overlay"
        >
            <img
                src={closeCross}
                onClick={props.closeNoticeModal}
                className="notice-modal-x"
                alt="Close"
            />
            <div className="notice-modal-text-container">

                <p className="notice-modal-title">
                    Notice de labellisation
                </p>

                <p
                    className="notice-modal-text"
                >
                    La distinction entre le code technique et fonctionnel n’est pas une définition générale, mais s’inscrit dans le cadre du projet CheckMyCode. Il a pour objectif de mesurer la qualité logicielle et de donner des indications pour l'optimiser. 

                    Pour se faire, plusieurs indicateurs entrent en jeu : 
                    <ul>
                    <li>Activité de développement </li>
                    <li>Volume de code smells </li>
                    <li>Couverture par les tests </li>
                    <li>Caractère fonctionnel </li>
                    </ul>
                    C’est le caractère fonctionnel du code qui nous intéresse ici car l’objectif est d’entraîner une IA capable de distinguer une ligne de code technique d’une ligne de code fonctionnelle. 
                    <b> Tout ce qui n’est pas du code technique est considéré comme fonctionnel et vice-versa</b>. Mais il est possible que du code technique et fonctionnel soit mélangé dans la même méthode, ce qui est considéré comme une mauvaise pratique. <b>Le nom des fonctions et des variables peut être une indication sur la classe d’appartenance du code</b>. 
                    <br />
                    <br />
                    <u style={{color: "#9B51E0"}}><b>Code technique (Ctrl+1) :</b></u>
                    <br />
                    Le code technique n’intervient pas dans la logique métier. C’est le support technique qui permet d’implémenter la solution. <b>Le code fonctionnel correspond à ce que l’on veut faire tandis que le code technique est comment le faire</b>.  
                    <br />
                    Exemples de code technique :  
                    <ul>
                    <li>Getter/setter </li>
                    <li>Gestion de base de données (add, save) </li>
                    <li>Gestion de fichiers </li>
                    <li>Chargement de données (dont des affectations) </li>
                    <li>Conversion de format de données </li>
                    <li>Appels API </li>
                    <li>Affichage de l’interface utilisateur </li>
                    <li>Infrastructure système </li>
                    <li>Connexion de diverses parties du programme </li>
                    </ul>
                    Exemples : <br />
                    <code style={{backgroundColor: "#f1f1f1"}}>
                        String url = "jdbc:psotgresql://localhost:5432/"<br />
                        String user = "postgres"<br />
                        String password = "postgres"<br />
                        Connection connection = DriverManager.getConnection(url, user, password)
                    </code>

                    <br />
                    <br />
                    <u  style={{color: "#219653"}}><b>Code fonctionnel (Ctrl+2) :</b></u>
                    <br />
                    Le code fonctionnel d’une application concerne toute la <b>logique “métier”</b> (business), c’est à dire toute la logique ayant pour but de répondre aux besoins définis par les clients de l’application cible.  
                    <br />
                    C’est un code où l’on va trouver une réflexion (intelligence) humaine. Si on trouve une forme d’intelligence de traitement de la donnée pour produire un nouveau résultat, on considère le code fonctionnel.  
                    <br />
                    Exemple de code fonctionnel :  
                    <ul>
                    <li>Opérations mathématiques (recherche d'un trajet GPS le plus court par exemple) </li>
                    <li>Dialogue utilisateur </li>
                    <li>Manipulation / transformation de données </li>
                    <li>Traitement des données avec résultats </li>
                    <li>Règles métiers de manière générale </li>
                    </ul>
                    <br />
                    Si vous avez un doute, vous pouvez toujours utiliser, avec parcimonie, le bouton <u  style={{color: "#F2994A"}}><b>Je ne sais pas (Ctrl+3)</b></u>.
                    <br />
                    Une fois la fonction labellisée, cliquez sur <b>fonction suivante (Ctrl+Entrer)</b>. Pour sauvegarder votre progresion, cliquez sur <b>sauvegarder (Ctrl+S)</b>.
                    <br />
                    <br />
                    <u><b>Exemple de labellisation :</b></u>
                    <br />
                    <img
                        src={codeExample}
                        className="notice-modal-code-example"
                        alt="code-example"
                    />

                    <button
                        onClick={props.closeNoticeModal}
                        className="notice-modal-button"
                    >
                        <span
                            className="modal-button-text"
                        >
                            Fermer
                        </span>
                    </button>
                </p>
            </div>

        </Modal>
    )
}
