import React from "react";
import {useNavigate} from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../auth/authConfig";

import "./AuthBtn.css"

export default function AuthBtn(props) {

    const { instance } = useMsal();

    let navigate = useNavigate();
    
    const connectBtnClicked = () => {
        instance.loginPopup(loginRequest).catch(e => {
            console.error(e);
        }).then(() => {
            props.requestProfileData();
            navigate("/options");
        });
    }
    
    return (
        <button id="authBtn" onClick={connectBtnClicked}>
            Se connecter
        </button>
    )
}