import React, { useEffect } from "react";

import LabelPageTop from "../../containers/labelPageTop/LabelPageTop";
import LabelPageBottom from "../../containers/labelPageBottom/LabelPageBottom";

import DailyChallengeModal from "../../components/modals/DailyChallengeModal";
import NoticeModal from "../../components/modals/NoticeModal";
import SaveModal from "../../components/modals/SaveModal";

import "./Labelling.css"
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";

export default function Labelling(props) {

    //retrieve user data from sessionStorage
    const userId = sessionStorage.getItem("userId");
    const userName = sessionStorage.getItem("userName");
    const idToken = sessionStorage.getItem("idToken");

    // State for timing the loading of the page
    const [isDoneLoading, setIsDoneLoading] = React.useState(false);

    // ====== SCORES ======
    const [congratulated, setCongratulated] = React.useState(false);
    const [sumPrevScore, setsumPrevScore] = React.useState(0);
    const dailyChallengeGoal = 50;
    const [currentScore, setCurrentScore] = React.useState(0);
    const incrementCurrentScore = () => {
        setCurrentScore(currentScore + 1);
    }
    const decrementCurrentScore = () => {
        if (currentScore > 0) {
            setCurrentScore(currentScore - 1);
        }
    }

    async function fetchScore() {
        const result = await fetch(props.backendURL + '/scores/get/' + userId + '/' + userName, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + idToken
                }
            })
          .then(response => response.json())
          .then(data => {
            return data;
          }
          ).catch(error => console.error(error))
        
          setCurrentScore(result.daily_score);
          setsumPrevScore(result.sum_prev_score);
    }

    const saveScore = async () => {
        const result = await fetch(props.backendURL + '/scores/save/' + userId, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + idToken
          },
          body: JSON.stringify({
            daily_score: currentScore
          })
        }
        ).then(response => response.json())
          .then(data => {
            return data;
          }
          ).catch(error => console.error(error))
    }

    // ====== MODALS ======
    const isOpenOnLoad = true
    const [dailyChallengeModalIsOpen, setDailyChallengeModalIsOpen] = React.useState(isOpenOnLoad);
    const openDailyChallengeModal = () => setDailyChallengeModalIsOpen(true);
    const closeDailyChallengeModal = () => setDailyChallengeModalIsOpen(false);

    const [noticeModalIsOpen, setNoticeModalIsOpen] = React.useState(false);
    const openNoticeModal = () => setNoticeModalIsOpen(true);
    const closeNoticeModal = () => setNoticeModalIsOpen(false);

    const [saveModalIsOpen, setSaveModalIsOpen] = React.useState(false);
    const openSaveModal = () => setSaveModalIsOpen(true);
    const closeSaveModal = () => setSaveModalIsOpen(false);

    if (!dailyChallengeModalIsOpen
        && currentScore === dailyChallengeGoal
        && !congratulated) { 
            setCongratulated(true);
            openDailyChallengeModal();
    }

    // ====== DATA ======
    const [isFirstLoadingFunction, setIsFirstLoadingFunction] = React.useState(false);
    const updateIsFirstLoadingFunction = state => setIsFirstLoadingFunction(state)

    const initialFetchedData = {
        id: "",
        repo: "",
        path: "src/Default.java",
        url: "https://github.com/nobody/nothing",
        functionName: "",
        code: ["public string defaultFunction(){", "    String str = 'this is the default function for labelwin';", "    return str;", "}"],
        docstring: ["default function"],
        language: "Java",
        class: {},
        labelingDone: false,
        indexLinesToLabel: [2, 3]
    }
    const [fetchedData, setFetchedData] = React.useState(initialFetchedData);

    const updateFetchedDataClass = (editedLineIndex, editedLineContent, userAnswer) => {
        if (!fetchedData.class[editedLineIndex] && userAnswer !== "IDK") { // nothing -> class
            incrementCurrentScore();
        } else if (fetchedData.class[editedLineIndex] && fetchedData.class[editedLineIndex][1] === "IDK" 
        && userAnswer !== "IDK"){ // idk -> class
            incrementCurrentScore();
        } else if (fetchedData.class[editedLineIndex] && fetchedData.class[editedLineIndex][1] !== "IDK"
        && userAnswer === "IDK"){ // class -> idk
            decrementCurrentScore();
        }
        let newResult = {};
        newResult[editedLineIndex] = [editedLineContent, userAnswer]
        setFetchedData((prevState) => ({
            ...prevState,
            class: {...prevState.class,  ...newResult}
        }));
    }

    const setLabelingDoneTrue = () => {
        setFetchedData((prevState) => ({
            ...prevState,
            labelingDone: true
        }));
    }

    const setLabelingDoneFalse = () => {
        setFetchedData((prevState) => ({
            ...prevState,
            labelingDone: false
        }));
    }

    const resetFetchedData = () => {
        setFetchedData(initialFetchedData);
    }

    const [isDataFetchNeeded, setIsDataFetchNeeded] = React.useState(true);
    const dataFetchNeeded = () => setIsDataFetchNeeded(true);
    const dataFetchDone = () => setIsDataFetchNeeded(false);

    const [isSaveNeeded, setIsSaveNeeded] = React.useState(false);
    const saveNeeded = () => setIsSaveNeeded(true);
    const saveDone = () => setIsSaveNeeded(false);

    useEffect(() => {
        async function fetchData() {
            return await fetch(props.backendURL + '/dataset/get/' + userId + '/' + localStorage.getItem('languages'), {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + idToken
                }
            })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => console.error(error))
        }

        if (isDataFetchNeeded) {
            fetchScore();
            fetchData().then(data => {
                setFetchedData({
                    id: data._id,
                    repo: data.repo,
                    path: data.path,
                    url: data.url,
                    functionName: data.func_name,
                    code: data.code.split('\n'),
                    docstring: data.docstring.split("\n"),
                    language: data.language,
                    class: data.class == null ? {} : data.class,
                    labelingDone: data.labeling_done,
                    indexLinesToLabel: data.indexLinesToLabel
                }
                );
                dataFetchDone();
                setIsDoneLoading(true);
            });
        }
    }, [isDataFetchNeeded]);

    const saveData = async () => {
        return await fetch(props.backendURL + '/dataset/save/' + userId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            },
            body: JSON.stringify({
                id: fetchedData.id,
                class: fetchedData.class,
                labeling_done: fetchedData.labelingDone
            })
        })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => console.error(error))
    }

    useEffect(() => {
        if (isSaveNeeded) {
            setIsDoneLoading(false);
            saveScore();
            saveData();
            Promise.all([saveScore(), saveData()]).then(() => {
                resetFetchedData();
                saveDone();
                dataFetchNeeded();
            }
            ).catch(error => console.error(error))
        }
    }, [isSaveNeeded]);

    // ====== DISPLAY ======
    if(isDoneLoading){
    return (
        <div id="labelling-page">

            <DailyChallengeModal
                dailyChallengeGoal={dailyChallengeGoal}
                currentScore={currentScore}
                dailyChallengeModalIsOpen={dailyChallengeModalIsOpen}
                closeDailyChallengeModal={closeDailyChallengeModal}
            />

            <NoticeModal 
                noticeModalIsOpen={noticeModalIsOpen}
                closeNoticeModal={closeNoticeModal}
            />

            <SaveModal
                saveModalIsOpen={saveModalIsOpen}
                closeSaveModal={closeSaveModal}
            />

            <h1>
                Fonction à analyser
            </h1>
            <LabelPageTop
                description={fetchedData.docstring}
                language={fetchedData.language}
                url={fetchedData.url}
                path={fetchedData.path}
                
                openDailyChallengeModal={openDailyChallengeModal}
                dailyChallengeGoal={dailyChallengeGoal}
                currentScore={currentScore}

                setLabelingDoneFalse={setLabelingDoneFalse}
                saveNeeded={saveNeeded}
                openSaveModal={openSaveModal}
            />
            <LabelPageBottom
                openNoticeModal={openNoticeModal}
                
                functionToLabel={fetchedData.code}
                indexLineToLabelFromFunction={fetchedData.indexLinesToLabel}
                functionLabelsResult={fetchedData.class}
                updateFetchedDataClass={updateFetchedDataClass}

                setLabelingDoneTrue={setLabelingDoneTrue}
                saveDataAndGetNewOne={saveNeeded}

                isFirstLoadingFunction = {isFirstLoadingFunction}
                updateIsFirstLoadingFunction = {updateIsFirstLoadingFunction}

                openLanguageChoiceModal={props.openLanguageChoiceModal}

                setLabelingDoneFalse={setLabelingDoneFalse}
                saveNeeded={saveNeeded}
                openSaveModal={openSaveModal}
            />
        </div>
    )
    } else {
        return (
            <LoadingSpinner />
        )
    }
}