import React, { useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import "./ScoreIndicator.css";

export default function ScoreIndicator(props) {

    let maxScore = props.dailyChallengeGoal;

    return (
        <div id="score-indicator"
        >
            <CircularProgressbar
                minValue={0}
                maxValue={maxScore}
                value={props.currentScore}
                text={`${props.currentScore} sur ${maxScore}`}
                styles={{
                    // Customize the root svg element
                    root: {
                        cursor: 'pointer',
                    },
                    // Customize the path, i.e. the "completed progress"
                    path: {
                        // Path color
                        stroke: '#207EA5',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Customize transition animation
                        //transition: 'stroke-dashoffset 0.5s ease 0s',
                        // Rotate the path
                        transform: 'rotate(0.5turn)',
                        transformOrigin: 'center center',
                    },
                    // Customize the circle behind the path, i.e. the "total progress"
                    trail: {
                        // Trail color
                        stroke: '#C5C5C5',
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: 'butt',
                        // Rotate the trail
                        transform: 'rotate(0.5turn)',
                        transformOrigin: 'center center',
                    },
                    // Customize the text
                    text: {
                        // Text color
                        fill: '#207EA5',
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '15px',
                        lineHeight: '18px',
                    },
                    // Customize background - only used when the `background` prop is true
                    background: {
                        fill: '#3e98c7',
                    },
                }}
            />
        </div>
    )
}