import React from "react";

import "./Home.css"
import homeBackground from "../../assets/home-background.png"
import AuthBtn from "../../components/btns/authBtn/AuthBtn"

export default function Home(props) {

    return (
        <div id="home">
            <img id="left-bck-img" src={homeBackground} />
            <img id="right-bck-img" src={homeBackground} />
            <div id="main-info-bloc">
                <div id="main-info-text-container">

                    <h1>
                        Bienvenue !
                    </h1>
                    <p>
                        Bienvenue sur l'outil de labellisation <b>LabelWin</b>, connectez-vous avant de pouvoir commencer.
                    </p>
                    <hr />
                    <AuthBtn 
                        requestProfileData={props.requestProfileData}
                    />
                </div>
            </div>
        </div>
    )
}