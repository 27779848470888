import React from "react";

export default function LabelBtn(props) {

    const customStyle = {
        color: props.isBtnHover ? "white" : props.color,
        backgroundColor: props.isBtnHover ? props.color : "white",
        borderColor: props.color,

        width: "100%",
        borderWidth: "2px",
        boxSizing: "border-box",
        fontFamily: "'Poppins', 'Arial', sans-serif",
        fontSize: "18px",
        fontWeight: "500",
        padding: "5px",
        borderRadius: "5px",
        cursor: "pointer",
        margin: "3px"
    }

    return (
        <button
            style={customStyle}
            onMouseOver={() => props.labelBtnIsHover(props.btnId)}
            onMouseLeave={props.noLabelBtnAreHover}
            onClick={() => props.labelBtnIsClicked(props.btnValue)}
            title={props.btnTitle}
        >
            {props.innerText}
        </button>
    )
}