import './App.css';

// React-router components importation
import { Routes, Route } from "react-router-dom"
import React, { useEffect } from 'react';
import {useNavigate} from "react-router-dom";
// Pages importation
import Home from './pages/home/Home';
import Labelling from './pages/labelling/Labelling'
import Options from './pages/options/Options';
import LanguageChoiceModal from "./components/modals/LanguageChoice";

import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "./auth/authConfig";
import { callMsGraph } from "./auth/graph";

function App() {

  let backendURL = "";
  if(process.env.NODE_ENV === "development"){
    backendURL = process.env.REACT_APP_BACK_URL_DEV;
  } else {
    backendURL = process.env.REACT_APP_BACK_URL_PROD;
  }

  // ====== USER DATA ======
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = React.useState(null);

  const requestProfileData = () => {
    const request = {
        ...loginRequest,
        account: accounts[0]
    };

    function onSuccess(response) {
      sessionStorage.setItem('idToken', response.idToken);
      sessionStorage.setItem('idTokenExpiration', response.idTokenClaims.exp);
      callMsGraph(response.accessToken).then(response => {
          setGraphData(response);
          sessionStorage.setItem('userId', response.id);
          sessionStorage.setItem('userName', response.displayName);
      });
    }

    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      onSuccess(response);
    }).catch((e) => {
      // instance.acquireTokenPopup(request).then((response) => {
      //   onSuccess(response);
      // }).catch((e) => {
      //   console.error(e);
      // });
      instance.acquireTokenRedirect(request);
      console.error(e);
    });
  }

  useEffect(() => {
    // if jwt expired, renew it. If id is missing, request it
    if(isAuthenticated && 
      ((sessionStorage.getItem('idTokenExpiration') &&
      sessionStorage.getItem('idTokenExpiration') < Math.floor(Date.now() / 1000)) ||
      !sessionStorage.getItem('userId'))
      ) {
      requestProfileData();
    }
  }, [isAuthenticated]);

  // ====== Option Modal ======
  const [LanguageChoiceModalIsOpen, setLanguageChoiceIsOpen] = React.useState(false);
  const openLanguageChoiceModal = () => setLanguageChoiceIsOpen(true);
  const closeLanguageChoiceModal = () => setLanguageChoiceIsOpen(false);

  let navigate = useNavigate();
  return (
    <div className="App">

      <LanguageChoiceModal
        LanguageChoiceModalIsOpen={LanguageChoiceModalIsOpen}
        closeLanguageChoiceModal={closeLanguageChoiceModal}
      />
      
      <Routes>
        
        <Route path="/" element={
          <>
          <UnauthenticatedTemplate>
            <Home requestProfileData={requestProfileData}/>
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {() => navigate("/options")}
          </AuthenticatedTemplate>
          </>
        }/>

        <Route path="/options" element={
          <>
          <UnauthenticatedTemplate>
            {() => navigate("/")}
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Options openLanguageChoiceModal={openLanguageChoiceModal}/>
          </AuthenticatedTemplate>
          </>
        }/>
        <Route path="/labelling" element={
          <>
          <UnauthenticatedTemplate>
            {() => navigate("/")}
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            <Labelling backendURL={backendURL} openLanguageChoiceModal={openLanguageChoiceModal} />
          </AuthenticatedTemplate>
          </>
        }/>
        
        <Route path="*" element={
          <>
          <UnauthenticatedTemplate>
            {() => navigate("/")}
          </UnauthenticatedTemplate>
          <AuthenticatedTemplate>
            {() => navigate("/options")}
          </AuthenticatedTemplate>
          </>
        }/>
        
      </Routes>
    </div>
  );
}

export default App;
