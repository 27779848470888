import React from "react";
import Modal from 'react-modal';

import "./Modals.css";
import "./SaveModal.css";
import doneHuman from "../../assets/doneHuman.png";

Modal.setAppElement('#root');

export default function SaveModal(props) {
    return (
        <Modal
            isOpen={props.saveModalIsOpen}
            onAfterOpen={() => {}}
            onAfterClose={() => { document.querySelector('body').style.overflow = 'auto'; }}
            onRequestClose={props.closeSaveModal}
            contentLabel="Save Modal"
            className="save-modal"
            overlayClassName="overlay"
        >
            <div
                className="save-modal-bckg"
            />

            <img 
                src={doneHuman}
                alt="Done Human"
                className="save-modal-human"
            />

            <div className="save-modal-text-container">
                <p className="save-modal-title">
                    Merci de ta contribution !
                </p>
                <p className="save-modal-text">
                    Ta progression a bien été sauvegardée.
                    <br /><br />
                    À bientôt pour continuer d’apporter ta pierre à l’édifice !
                </p>
            </div>

            <button
                onClick={props.closeSaveModal}
                className="save-modal-button"
            >
                <span
                    className="modal-button-text"
                >
                Fermer
                </span>
            </button>
        </Modal>
    )
}
