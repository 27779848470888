import React, { useCallback, useEffect, useState } from "react";

import IDE from "../../components/IDE/IDE";
import LabelBtns from "../../containers/labelPageBottom/labelBtns/LabelBtns";
import NextFuncBtn from "../../components/btns/nextFuncBtn/NextFuncBtn";

import "./LabelPageBottom.css";
import infoIcon from "../../assets/info-icon.svg";



export default function LabelPageBottom(props) {

    // ============================================================================
    // CONSTANTS
    const ALL_LINES = document.getElementsByClassName("cm-line");
    const LINE_COLORS = {
        technicalColor: "#9b51e051",
        functionalColor: "#21965451",
        iDKColor: "#f2984a51",
        linesToLabelColor: "#7a7a7a50",
        newActiveLineColor: "#ffffff50",
        oldLineColor: "#282C34"
    }

    // ============================================================================
    // VARIABLES
    let currentLineDOMIndexElem;
    let currentLineDOMContentElem;
    let currentLineIndexFromDOMElem;
    let currentLineContentFromDOMElem;

    let selectedLineByCursorIndexDOMElem;
    let selectedLineByCursorDOMElem;

    // ============================================================================
    // STATES
    // State for the "go to next function" button
    const [isNextFunctionBtnDisabled, setIsNextFunctionBtnDisabled] = useState(true);
    const switchIsNextFunctionBtnDisabledFalse = () => {
        setIsNextFunctionBtnDisabled(false);
    }
    const switchIsNextFunctionBtnDisabledTrue = () => {
        setIsNextFunctionBtnDisabled(true);
    }
    const functionToLabel = props.functionToLabel;
    const indexLineToLabelFromFunction = props.indexLineToLabelFromFunction;
    let functionLabelsResult = props.functionLabelsResult;

    // State for the current line that will be label on label button click
    const [currentLineIndex, setCurrentLineIndex] = useState(indexLineToLabelFromFunction[0])
    const updateCurrentLineIndex = index => setCurrentLineIndex(index)

    // ============================================================================
    // FUNCTIONS
    // Function to set variables corresponding to current line to label DOM elements (content and index) and to the inner html content of these elements
    const setCurrentLineDOMElem = () => {
        if (currentLineIndex !== -1) {
            currentLineDOMIndexElem = document.getElementsByClassName("cm-gutter cm-lineNumbers").item(0).children.item(currentLineIndex);
            currentLineDOMContentElem = document.getElementsByClassName("cm-content").item(0).children.item(currentLineIndex - 1);
    
            currentLineIndexFromDOMElem = document.getElementsByClassName("cm-gutter cm-lineNumbers").item(0).children.item(currentLineIndex).outerText;
            currentLineContentFromDOMElem = document.getElementsByClassName("cm-content").item(0).children.item(currentLineIndex - 1).outerText;

        } else {
            currentLineDOMIndexElem = null;
            currentLineDOMContentElem = null;
    
            currentLineIndexFromDOMElem = null;
            currentLineContentFromDOMElem = null;
        }
    }

    // Function to update results in local storage after clicking on a label button
    const updateResults = (userAnswer, editedLineIndex, editedLineContent) => {
        // Add new result
        if (parseInt(editedLineIndex) > 0) {
            props.updateFetchedDataClass(editedLineIndex, editedLineContent, userAnswer);
        }
    }

    // Function to initialize the current line on page loading, seek for the first line that is unlabelled
    const initializeCurrentLine = () => {
        let isCurrentLineInit = false;
        indexLineToLabelFromFunction.forEach(lineIndex => {
            if (!functionLabelsResult.hasOwnProperty(lineIndex) && !isCurrentLineInit) {
                updateCurrentLineIndex(
                    lineIndex
                )
                isCurrentLineInit = true;
            }
        })
        isCurrentLineInit = false
    }

    // Function to update the current line to label if user click on a line
    const updateCurrentLineIfCursorMoved = () => {
        // TRY CATCH TO PREVENT CRASH BY DOUBLE CLICK ON IDE
        try {
            if (document.getElementsByClassName("cm-gutterElement cm-activeLineGutter").item(0) != null) {
                selectedLineByCursorIndexDOMElem = document.getElementsByClassName("cm-gutterElement cm-activeLineGutter").item(0).outerText;
                if (indexLineToLabelFromFunction.includes(parseInt(selectedLineByCursorIndexDOMElem)) 
                && selectedLineByCursorIndexDOMElem !== currentLineIndex && selectedLineByCursorIndexDOMElem != null) {
                    updateCurrentLineIndex(selectedLineByCursorIndexDOMElem);
                    selectedLineByCursorDOMElem = document.querySelector(".cm-activeLineGutter");
                    selectedLineByCursorDOMElem.classList.remove("cm-activeLineGutter");
                } else if (selectedLineByCursorIndexDOMElem !== currentLineIndex 
                && selectedLineByCursorIndexDOMElem != null && !isNaN(parseInt(selectedLineByCursorIndexDOMElem, 10))) {
                    if (!props.isFirstLoadingFunction) {
                        updateCurrentLineIndex(-1);
                        selectedLineByCursorDOMElem = document.querySelector(".cm-activeLineGutter");
                        selectedLineByCursorDOMElem.classList.remove("cm-activeLineGutter");
                    } else {
                        props.updateIsFirstLoadingFunction(false);
                        selectedLineByCursorDOMElem = document.querySelector(".cm-activeLineGutter");
                        selectedLineByCursorDOMElem.classList.remove("cm-activeLineGutter");
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    // FUNCTIONS RELATED TO LINES COLORING
    // Function to update line colors according to user labels answer seek from local storage
    const updateAllLinesColor = () => {
        // Reset line indexes color
        for (let i = 0; i < functionToLabel.length; i++) {
            // Try catch to prevent error coming from overflowed lines
            try {
                document.getElementsByClassName("cm-gutter cm-lineNumbers").item(0).children.item(i).style.backgroundColor = LINE_COLORS.oldLineColor;
            } catch (error) {
                // error removed to clean console
                // console.error("line index field " + i + " cannot be colorized because it is not load yet (overflow)");
            }
        }

        // Color line to label
        indexLineToLabelFromFunction.forEach(line => {
            ALL_LINES.item(line - 1).style.backgroundColor = LINE_COLORS.linesToLabelColor;
            document.getElementsByClassName("cm-gutter cm-lineNumbers").item(0).children.item(line).style.backgroundColor = LINE_COLORS.oldLineColor;
        })

        // Color labelled line
        Object.entries(functionLabelsResult).forEach(lineAnswered => {
            try {
                document.getElementsByClassName("cm-gutter cm-lineNumbers").item(0).children.item(lineAnswered[1][1]).style.backgroundColor = LINE_COLORS.oldLineColor;
                switch (lineAnswered[1][1]) {
                    case "functional":
                        ALL_LINES.item(lineAnswered[0] - 1).style.backgroundColor = LINE_COLORS.functionalColor;
                        break;
                    case "technical":
                        ALL_LINES.item(lineAnswered[0] - 1).style.backgroundColor = LINE_COLORS.technicalColor;
                        break;
                    case "IDK":
                        ALL_LINES.item(lineAnswered[0] - 1).style.backgroundColor = LINE_COLORS.iDKColor;
                        break;
                    default:
                        break;
                }
            } catch (error) {
                console.error(error);
            }
        });
        colorCurrentLine();
    }

    // Function to update the color of the current line to label
    const colorCurrentLine = () => {
        if (currentLineDOMIndexElem != null && currentLineDOMContentElem != null) {
            currentLineDOMIndexElem.style.backgroundColor = LINE_COLORS.newActiveLineColor;
            if (!functionLabelsResult[currentLineDOMIndexElem.outerText]) {
                currentLineDOMContentElem.style.backgroundColor = LINE_COLORS.newActiveLineColor;
            }
        }
    }

    // FUNCTIONS RELATED TO THE GO TO NEXT FUNCTION BTN
    // Function to check if all lines of the function are labelled and, if so, enable the "go to next function" button
    const updateBtnState = () => {
        if (indexLineToLabelFromFunction.length === Object.keys(functionLabelsResult).length) {
            switchIsNextFunctionBtnDisabledFalse();
        }
    }

    // Function to go next unlabelled line when a label button is clicked
    const goToNextLine = () => {
        if (currentLineIndex === indexLineToLabelFromFunction[indexLineToLabelFromFunction.length - 1]) { // if reach end...
            updateCurrentLineIndex(indexLineToLabelFromFunction[0]) // ...go to first line
        } else if (indexLineToLabelFromFunction.length === Object.keys(functionLabelsResult).length) {
            updateCurrentLineIndex(indexLineToLabelFromFunction[indexLineToLabelFromFunction.indexOf(parseInt(currentLineIndex)) + 1])
        } else {
            let tempLineIndex = indexLineToLabelFromFunction[indexLineToLabelFromFunction.indexOf(parseInt(currentLineIndex)) + 1]
            while ((Object.keys(functionLabelsResult).includes(tempLineIndex.toString()))) {
                tempLineIndex = indexLineToLabelFromFunction[indexLineToLabelFromFunction.indexOf(parseInt(tempLineIndex)) + 1]
                if(!tempLineIndex){
                    tempLineIndex = indexLineToLabelFromFunction[indexLineToLabelFromFunction.indexOf(parseInt(currentLineIndex)) + 1]
                    break;
                }
            }
            updateCurrentLineIndex(tempLineIndex)
        }
    }

    // FUNCTIONS PASSED TO CHILD AS PROPS
    // Function, passed to child, that occurs when a label button is clicked
    const labelBtnIsClicked = userLabelAnswer => {
        if (currentLineIndex !== -1) {
            updateResults(userLabelAnswer, currentLineIndexFromDOMElem, currentLineContentFromDOMElem);
            // updateBtnState(); // done in useEffect
            goToNextLine();
        }
        updateAllLinesColor();
    }

    // Function, passed to child, that occurs when the IDE (MirrorCode) is update
    const onIDEUpdate = () => {
        setCurrentLineDOMElem();
        updateCurrentLineIfCursorMoved();
        updateAllLinesColor();
    }

    // Function, passed to child that occurs when the "go to next function" button is clicked
    const nextFunctionBtnIsClicked = () => {
        if (!isNextFunctionBtnDisabled) {
            if (localStorage.getItem('languages') !== null && localStorage.getItem('languages') !== "") {
                props.setLabelingDoneTrue();
                props.saveDataAndGetNewOne();
                switchIsNextFunctionBtnDisabledTrue();
                props.updateIsFirstLoadingFunction(true);
                initializeCurrentLine();
            } else {
                props.openLanguageChoiceModal();
            }
        }
    }

    //Function for shortcut
    const handleKeyPress = useCallback((event) => {
        if (event.ctrlKey  === true) {
            event.stopPropagation();
            event.preventDefault();
            switch(event.key) {
                case "&":
                case "1":
                    event.preventDefault();
                    labelBtnIsClicked('technical')
                    break;
                case "é" :
                case "2" :
                    labelBtnIsClicked('functional')
                    break;
                case "\"" :
                case "3" :
                    labelBtnIsClicked('IDK')
                    break;
                case "s" :
                case "S" :
                    props.setLabelingDoneFalse();
                    props.saveNeeded();
                    props.openSaveModal();
                    break;
                case "Enter" :
                    nextFunctionBtnIsClicked();
                    break;
                default :
                    break;
            }
        }
    }, [labelBtnIsClicked, props, nextFunctionBtnIsClicked]);

    // ============================================================================    
    // UseEffect

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);
    
        // remove the event listener
        return () => {
          document.removeEventListener('keydown', handleKeyPress);
        };
      }, [handleKeyPress]);

    useEffect(() => {
        initializeCurrentLine();
    }, [])

    useEffect(() => {
        initializeCurrentLine();
    }, [indexLineToLabelFromFunction])

    useEffect(() => {
        updateBtnState();
    }, [functionLabelsResult]);

    // ============================================================================ 
    return (
        <div id="label-page-bottom-container">
            <IDE
                onIDEUpdate={onIDEUpdate}
                functionToLabel={functionToLabel}
            />
            <div id="label-choice-title">
                <p>
                    Choisissez la catégorie correspondante selon vous :
                </p>
                <img src={infoIcon} onClick={props.openNoticeModal} alt="info-icon" />
            </div>
            <LabelBtns labelBtnIsClicked={labelBtnIsClicked} />
            <NextFuncBtn isNextFuncBtnDisable={isNextFunctionBtnDisabled} nextFunctionBtnIsClicked={nextFunctionBtnIsClicked} />
        </div>
    )
}