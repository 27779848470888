import React from "react";
import Modal from 'react-modal';

import "./Modals.css";
import "./DailyChallengeModal.css";
import dailyChallengeHuman from "../../assets/dailyChallengeHuman.png";
import doneHuman from "../../assets/doneHuman.png";

Modal.setAppElement('#root');

export default function DailyChallengeModal(props) {

    let isChallengeDone = false;
    if(props.currentScore >= props.dailyChallengeGoal) {
        isChallengeDone = true;
    }

    const challengeToDo = () => {
        return (
            <div>
                <img
                    src={dailyChallengeHuman}
                    alt="Daily Challenge Human"
                    className="daily-challenge-modal-human"
                />

                <div className="daily-challenge-modal-all-text">
                    <p
                        className="daily-challenge-modal-title"
                        data-testid="daily-challenge-modal-title"
                    >
                        Challenge du jour !
                    </p>
                    <p
                        className="daily-challenge-modal-text"
                        data-testid="daily-challenge-modal-text"
                    >
                        À chaque jour, son nouveau challenge !
                        <br /><br />
                        Ton objectif du jour est d’analyser
                        <span
                            className="daily-challenge-modal-text-bold"
                        >
                            &#160;{props.dailyChallengeGoal} lignes&#160;
                        </span>
                        avant la fin de ta session.
                        <br /><br />
                        Puisse la force être avec toi.
                    </p>
            
                    <button
                        onClick={props.closeDailyChallengeModal}
                        className="daily-challenge-modal-button"
                    >
                        <span
                            className="modal-button-text"
                        >
                            C'est parti !
                        </span>
                    </button>
                </div>
            </div>
        )
    }

    const challengeDone = () => {
        return (
            <div>
                <img
                    src={doneHuman}
                    alt="Done Human"
                    className="done-modal-human"
                />

                <div className="daily-challenge-modal-all-text">
                    <p
                        className="daily-challenge-modal-title"
                        data-testid="daily-challenge-modal-title"
                    >
                        Challenge relevé !
                    </p>
                    <p
                        className="daily-challenge-modal-text"
                        data-testid="daily-challenge-modal-text"
                    >
                        Félicitations, Tu as atteint ton objectif !
                        Si tu le souhaites, tu peux bien évidemment continuer sur ta lancée.
                        <br /><br />
                        <b>N'oublie pas de sauvegarder ta progression !</b>
                        <br /><br />
                        Et revient demain ! Rome ne s'est pas faite en un jour...
                    </p>
            
                    <button
                        onClick={props.closeDailyChallengeModal}
                        className="daily-challenge-modal-button"
                    >
                        <span
                            className="modal-button-text"
                        >
                            Compris
                        </span>
                    </button>
                </div>

            </div>
        )
    }

    return (
        <Modal
            isOpen={props.dailyChallengeModalIsOpen}
            onAfterOpen={() => { }}
            onAfterClose={() => { document.querySelector('body').style.overflow = 'auto'; }}
            onRequestClose={props.closeDailyChallengeModal}
            contentLabel="Daily Challenge Modal"
            className="daily-challenge-modal"
            overlayClassName="overlay"
        >
            <div
                className="daily-challenge-modal-bckg"
            />
            {isChallengeDone? challengeDone() : challengeToDo()}
        
        </Modal>
    )
}
