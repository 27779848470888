import React, { useState } from "react";

import saveIcon from "../../../assets/save-icon.svg";
import "./SaveBtn.css";

export default function SaveBtn(props){

    const saveAndOpenSaveModal = () => {
        props.setLabelingDoneFalse();
        props.saveNeeded();
        props.openSaveModal();
    }

    return (
        <button 
        id="save-btn" 
        title = "Ctrl + s"
        onClick={saveAndOpenSaveModal}
        >
            <p>
                Sauvegarder
            </p>
            <img
                src={saveIcon}
                alt="icone du bouton de sauvegarde"
            />
        </button>
    )
}