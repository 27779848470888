import React from "react";

import SaveBtn from "../../../components/btns/saveBtn/SaveBtn";
import ScoreIndicator from "../../../components/scoreIndicator/ScoreIndicator";

import "./SaveAndScoreBtns.css"

export default function SaveAndScoreBtns(props) {

    return (
        <div id="save-score-container">
            <SaveBtn 
                setLabelingDoneFalse={props.setLabelingDoneFalse}
                saveNeeded={props.saveNeeded}
                openSaveModal={props.openSaveModal}
            />
            <div onClick={props.openDailyChallengeModal}>
                <ScoreIndicator currentScore={props.currentScore} dailyChallengeGoal={props.dailyChallengeGoal}/>
            </div>
        </div>
    )
}