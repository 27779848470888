import React from "react";
import Modal from 'react-modal';

import "./Modals.css";
import "./LanguageChoice.css";
import backgroundTopModal from "../../assets/language-choice.png";
import {useNavigate} from "react-router-dom";

Modal.setAppElement('#root');

export default function LanguageChoiceModal(props) {
    var languageChoiced = [];
    let navigate = useNavigate();

    function setLanguage(isChecked, nameLanguage) {
        if (isChecked) {
            languageChoiced.push(nameLanguage)
        }
    }

    const btnClicked = () => {
        let languages = document.getElementsByName("language")
        for (let index = 0; index < languages.length; index++){
            setLanguage(languages[index].checked,languages[index].id)
        }

        if (languageChoiced.length !== 0) {
            localStorage.clear();
            localStorage.setItem('languages', languageChoiced);
            navigate("/labelling")
            props.closeLanguageChoiceModal()
        } else {
            alert("Veuillez choisir au moins un langage.")
        }
    }

    const isInLocalStorage = (lang) => {
        const languages = localStorage.getItem('languages');
        if (languages !== null) {
            return languages.split(',').includes(lang)
        } else {
            return true
        }
    }

    return (
        <Modal
            isOpen={props.LanguageChoiceModalIsOpen}
            onAfterOpen={() => { }}
            onAfterClose={() => { document.querySelector('body').style.overflow = 'auto'; }}
            onRequestClose={props.closeLanguageChoiceModal}
            contentLabel="Language Choice Modal"
            className="language-Choice-modal"
            overlayClassName="overlay"
        >
            <div
                className="language-Choice-modal-bckg"
            >
            <img
                src={backgroundTopModal}
                alt="Daily Challenge Human"
                className="language-Choice-modal-human"
            />
            </div>

            <div className="language-Choice-modal-all-text">
                <p
                    className="language-Choice-modal-title"
                    data-testid="language-Choice-modal-title"
                >
                    Choisissez vos langages !
                </p>
                <div
                    className="language-Choice-modal-text"
                    data-testid="language-Choice-modal-text"
                >
                    <div>
                    <input type="checkbox" id="java" name="language" value="java" defaultChecked={isInLocalStorage("java")}></input>
                    <label htmlFor="java">Java</label>
                    </div>

                    <div>
                    <input type="checkbox" id="javascript" name="language" value="javascript" defaultChecked={isInLocalStorage("javascript")}></input>
                    <label htmlFor="javascript">Javascript</label>
                    </div>

                    <div>
                    <input type="checkbox" id="php" name="language" value="php" defaultChecked={isInLocalStorage("php")}></input>
                    <label htmlFor="php">Php</label>
                    </div>

                    <div>
                    <input type="checkbox" id="python" name="language" value="python" defaultChecked={isInLocalStorage("python")}></input>
                    <label htmlFor="python">Python</label>
                    </div>
                </div>
        
                <button
                    onClick={btnClicked}
                    className="language-Choice-modal-button"
                >
                    <span
                        className="modal-button-text"
                    >
                        Commencer
                    </span>
                </button>
            </div>

        </Modal>
    )
}
