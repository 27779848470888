import React from "react";

import FunctionInfos from "./functionInfos/FunctionsInfos";
import SaveAndScoreBtns from "./saveAndScoreBtns/SaveAndScoreBtns";

import "./LabelPageTop.css";

export default function LabelPageTop(props) {
    return (
        <div id="label-page-top-container">
            <div id="functions-infos-inside-top">
                <FunctionInfos
                    description={props.description}
                    language={props.language}
                    url={props.url}
                    path={props.path}
                 />
            </div>
            <SaveAndScoreBtns 
                currentScore={props.currentScore}
                dailyChallengeGoal={props.dailyChallengeGoal} 
                openDailyChallengeModal={props.openDailyChallengeModal}

                setLabelingDoneFalse={props.setLabelingDoneFalse}
                saveNeeded={props.saveNeeded}
                openSaveModal={props.openSaveModal}
                />
        </div>
    )
}