import React, { useState } from "react";

import FunctionInfoTextbox from "../../../components/functionInfoTextbox/FunctionInfoTextbox";

import "./FunctionInfos.css"

const functionInfosTextboxTitles = [
    "Description : ",
    "Langage : ",
    "URL : ",
    "Path : "
]


export default function FunctionInfos(props) {

    return (
        <div id="function-infos-container">
            <div id="right-text-box">
                <FunctionInfoTextbox 
                    infoTextBoxTitle={functionInfosTextboxTitles[0]} 
                    infoTextBoxInnerText={props.description} 
                    isH2Inline={false} 
                    isPInline={false} 
                />
            </div>
            <div id="middle-top-text-box">
                <FunctionInfoTextbox 
                    infoTextBoxTitle={functionInfosTextboxTitles[1]} 
                    infoTextBoxInnerText={[props.language]} 
                    isH2Inline={true} 
                    isPInline={true} 
                />
            </div>
            <div id="right-top-text-box">
                <FunctionInfoTextbox 
                    infoTextBoxTitle={functionInfosTextboxTitles[2]} 
                    infoTextBoxInnerText={[props.url]} 
                    isH2Inline={true} 
                    isPInline={true} 
                />
            </div>
            <div id="right-bottom-text-box">
                <FunctionInfoTextbox 
                    infoTextBoxTitle={functionInfosTextboxTitles[3]} 
                    infoTextBoxInnerText={[props.path]} 
                    isH2Inline={true} 
                    isPInline={true} 
                />
            </div>
        </div>
    )
}