import React, { useState } from "react";

import LabelBtn from "../../../components/btns/labelBtn/LabelBtn";
import "./LabelBtns.css";

export default function LabelBtns(props) {

    const [labbelingBtns, setlabellingBtns] = useState(
        [
            {
                btnId: "TechniqueBtn",
                btnInnerText: "Technique",
                btnValue: "technical",
                btnColor: "#9B51E0",
                btnIsHover: false,
                btnTitle : "Ctrl + 1"
            },
            {
                btnId: "Fonctionnel",
                btnInnerText: "Fonctionnel",
                btnValue: "functional",
                btnColor: "#219653",
                btnIsHover: false,
                btnTitle : "Ctrl + 2"
            },
            {
                btnId: "NeSaisPasBtn",
                btnInnerText: "Je ne sais pas",
                btnValue: "IDK",
                btnColor: "#F2994A",
                btnIsHover: false,
                btnTitle : "Ctrl + 3"
            },
        ]
    )

    let labelBtnIsHover = btnId => {
        setlabellingBtns(
            labbelingBtns.map(
                labellingBtn => {
                    return {
                        btnId: labellingBtn.btnId,
                        btnInnerText: labellingBtn.btnInnerText,
                        btnValue : labellingBtn.btnValue,
                        btnColor: labellingBtn.btnColor,
                        btnIsHover: labellingBtn.btnId == btnId ? true : false,
                        btnTitle : labellingBtn.btnTitle
                    }
                }
            )
        )
    }

    let noLabelBtnAreHover = () => {
        setlabellingBtns(
            labbelingBtns.map(
                labellingBtn => {
                    return {
                        btnId: labellingBtn.btnId,
                        btnInnerText: labellingBtn.btnInnerText,
                        btnValue : labellingBtn.btnValue,
                        btnColor: labellingBtn.btnColor,
                        btnIsHover: false,
                        btnTitle : labellingBtn.btnTitle
                    }
                }
            )
        )
    }


    return (
        <div id="label-btns-container">
            {
                labbelingBtns.map(
                    labellingBtn => {
                        return (
                            <LabelBtn
                                key={labellingBtn.btnId}
                                btnId={labellingBtn.btnId}
                                innerText={labellingBtn.btnInnerText}
                                color={labellingBtn.btnColor}
                                isBtnHover={labellingBtn.btnIsHover}
                                btnValue={labellingBtn.btnValue}
                                labelBtnIsHover={labelBtnIsHover}
                                noLabelBtnAreHover={noLabelBtnAreHover}
                                labelBtnIsClicked={props.labelBtnIsClicked}
                                btnTitle={labellingBtn.btnTitle}
                            />
                        )
                    }
                )
            }
        </div>
    )
}