import React from "react";

import "./FunctionInfoTextbox.css";

export default function FunctionInfoTextbox(props) {

    function isOverflown(element) {
        return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    }

    const styleConditioning = (className) => {
        var els = document.getElementsByClassName(className);
        for (var i = 0; i < els.length; i++) {
            var el = els[i];
            el.style.justifyContent = (isOverflown(el) ? 'none' : 'center');
            el.style.alignItems = (isOverflown(el) ? 'none' : 'center');
            //console.log("Element #" + i + " is " + (isOverflown(el) ? '' : 'not ') + "overflown.");
        }
    }

    styleConditioning("function-info-textbox");

    let lineNb = -1;
    return (
        <div id="function-info-textbox" className="function-info-textbox">
            <div id="function-info-textbox-inner-text">
                <h2 className={props.isH2Inline ? "h2-inline" : undefined}>
                    {props.infoTextBoxTitle}
                </h2>

                {
                    props.infoTextBoxInnerText.map(innerTextLine => {
                        lineNb++;
                        return (
                            <p className={props.isPInline ? "p-inline" : undefined} 
                                key={lineNb}>
                                {innerTextLine}
                            </p>
                        )
                    })
                }
            </div>
            {}
        </div>
    )
}