import React, { useEffect, useRef, useState } from "react";

import { useCodeMirror } from '@uiw/react-codemirror';
import './IDE.css'
import { oneDark } from '@codemirror/theme-one-dark';
import { javascript } from '@codemirror/lang-javascript';



export default function IDE(props) {

    const editor = useRef();
    const { setContainer } = useCodeMirror({
        container: editor.current,
        value: Object.values(props.functionToLabel).join("\n"),
        theme: oneDark,
        height: '100%',
        extensions: [javascript({ jsx: true })],
        readOnly: true,
        onUpdate: (value, viewUpdate) => {
            props.onIDEUpdate()
        }
    });

    useEffect(() => {
        if (editor.current) {
            setContainer(editor.current);
        }
    }, []);

    return (
        <div ref={editor} id="IDE-codeMirror" />
    );
}