import React, { useEffect } from "react";

export default function Options(props) {

    useEffect(() => {
        props.openLanguageChoiceModal();
    }, [props]);

    return (
        <div></div>
    )
}