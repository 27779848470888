import React from "react";

import "./NextFuncBtn.css";
import nextIconWhite from "../../../assets/next-icon-white.svg";
import nextIconGrey from "../../../assets/next-icon-grey.svg";

export default function NextFuncBtn(props) {

    const customStyle = {
        color: props.isNextFuncBtnDisable ? "#BDBDBD" : "white",
        backgroundColor: props.isNextFuncBtnDisable ? "white" : "#207EA5",
        border : props.isNextFuncBtnDisable ? "2px solid #BDBDBD" : "none",
        cursor : props.isNextFuncBtnDisable ? "not-allowed" : "pointer"
    }

    return (
        <button
            id="next-func-btn"
            style={customStyle}
            disabled={props.isNextFuncBtnDisable}
            onClick={props.nextFunctionBtnIsClicked}
            title = "Ctrl + Enter"
        >
            <p>
                Fonction suivante
            </p>
            <img src={props.isNextFuncBtnDisable? nextIconGrey : nextIconWhite} alt="" />
        </button>
    )
}